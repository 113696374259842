<!-- 
	This is the sign up page, it uses the dashboard layout in: 
	"./layouts/Default.vue" .
 -->

<template>
	<div class="set-stats">

		<!-- Sign Up Image And Headings -->
		<!--
		<div class="sign-up-header" style="background-image: url('images/bg-signup.jpg')">
			<div class="content">
				<h1 class="mb-5">Sign Up</h1>
				<p class="text-lg">Use these awesome forms to login or create new account in your project for free.</p>
			</div>
		</div>
		-->
		<!-- / Sign Up Image And Headings -->
		
		<!-- Sign Up Form -->
		<a-card :bordered="false" class="card-signup header-solid">

			<a-form-model
				id="components-form-demo-normal-login"
				:form="form"
				class="login-form"
				@submit="handleSubmit"
			>
				<a-form-model-item style="display: flex; justify-content: center; text-align: center">
					<a-date-picker v-model="date" placeholder="Дата игры" :format="'DD.MM.YYYY'" @change="onDateChange" :allowClear="false" />
				</a-form-model-item>

				<a-form-model-item :class="'set-stats__row'" v-for="(item, index) in items" :key="item.id">
					<a-row type="flex" :gutter="10">
						<a-col :xs="{ span: 10 }" :lg="{ span: 12 }" >
							<div v-if="index === 0" style="margin-bottom: 5px" >Игрок</div>
							<a-auto-complete
								v-model="item.name"
								:data-source="dataSource"
								placeholder="Имя игрока"
								:filter-option="filterOption"
								:allowClear="true"
								class="ant-dropdown-placement-topLeft"
								:defaultOpen="false"
								:open="item.name.length > 2 && focused"
								@focus="focused = true"
								@blur="focused = false"
							/>
						</a-col>
						<a-col :xs="{ span: 7 }" :lg="{ span: 6 }" >
							<div v-if="index === 0" style="margin-bottom: 5px" >Голы</div>
							<a-button-group>
								<a-button class="btn-minus" @click="item.goals > 0 ? item.goals-=1 : null">-</a-button>
								<a-input class="counter"  v-model="item.goals"/>
								<a-button class="btn-plus" @click="item.goals+=1">+</a-button>
							</a-button-group>
						</a-col>
						<a-col :xs="{ span: 7 }" :lg="{ span: 6 }" >
							<div v-if="index === 0" style="text-align: right; margin-bottom: 5px">Передачи</div>
							<a-button-group>
								<a-button class="btn-minus" @click="item.pass > 0 ? item.pass-=1 : null">-</a-button>
								<a-input class="counter"  v-model="item.pass"/>
								<a-button class="btn-plus" @click="item.pass+=1">+</a-button>
							</a-button-group>
						</a-col>
					</a-row>
				</a-form-model-item>

				<a-form-item>
					<a-button type="primary" block html-type="submit" class="login-form-button">
						Сохранить
					</a-button>
				</a-form-item>
			</a-form-model>

		</a-card>
		<!-- / Sign Up Form -->

	</div>
</template>

<script>

	import axios from "axios";
	import moment from "moment";
	import 'moment/locale/ru';

	export default ({
		data() {
			return {
				name: 'SetStats',
				date: moment().subtract(1, 'd'),
				result: [],
				items:[
					{
						id: Date.now(),
						name: '',
						goals: 0,
						pass: 0,
					},
				],
				dataSource: [
					{
						text: 'Subaru',
						value: '1'
					},
					{
						text: 'Audi',
						value: '2'
					},
				],
				// dateDB: null,
				focused: false,
			}
		},
		mounted() {
			this.getData();
			// this.dateDB = this.date;
		},
		beforeCreate() {
			// Creates the form and adds to it component's "form" property.
			this.form = this.$form.createForm(this, { name: 'normal_login' });
		},
		watch: {
			items: {
				handler() {
					const lastItem = this.items[this.items.length - 1];

					const newItem = {
						id: this.items.length + 1,
						name: '',
						goals: 0,
						pass: 0,
					};

					if (lastItem.name !== '' && (lastItem.goals !== 0 || lastItem.pass !== 0)) {
						this.items.push(newItem);
					}

					this.items.forEach((element, index) => {
						if (element.name === '' && element.goals === 0 && element.pass === 0 && index !== this.items.length - 1) {
							this.items.splice(index, 1);
						}
					});
				},
				deep: true
			},/*
			date(newDate) {
				console.log('newDate', newDate);
				this.dateDB =  moment(newDate).format('DD-MM-YYYY');
			}*/
		},
		computed: {

			dateDB() {
				return moment(this.date).format('YYYY-MM-DD');
			}

		},
		methods: {
			getData() {
				const data = {
					method: 'set-stats-page',
					date: this.dateDB,
				}

				axios
					.post('https://hcultras.kz/api/', JSON.stringify(data))
					.then(response => {
						if (response.status === 200) {
							this.dataSource = response.data.players;
							if (response.data.stats.length) {
								this.items = response.data.stats;
							} else {
								this.items = [{
									id: Date.now(),
									name: '',
									goals: 0,
									pass: 0,
								}];
							}
						}
					})
					.catch(error => console.log(error));
			},

			onDateChange(date, dateString) {
				const data = {
					method: 'set-stats-page',
					date: this.dateDB,
					type: 'new-date',
				}

				axios
					.post('https://hcultras.kz/api/', JSON.stringify(data))
					.then(response => {
						if (response.status === 200) {
							if (response.data.stats.length) {
								this.items = response.data.stats;
							} else {
								this.items = [{
									id: Date.now(),
									name: '',
									goals: 0,
									pass: 0,
								}];
							}
						}
					})
					.catch(error => console.log(error));
			},

			filterOption(input, option) {
				return (
						input.length > 2 ? option.componentOptions.children[0].text.toUpperCase().includes(input.toUpperCase()) : null
				);
			},

			showAutocomplete(state) {
				console.log(state)
				// item.name.length > 1
				if (state) {
					// return state
				}
				return true
			},

			savePlayers() {
				this.result = [...this.items];
				this.result.forEach((element, index) => {
					if (element.goals === 0 && element.pass === 0) {
						this.result.splice(index, 1);
					}
				});

				const format = this.result.map(({name: id, goals, pass, stats_id})=>({
					id : parseInt(id),
					goals: goals > 0 ? goals : null,
					pass: pass > 0 ? pass : null,
					stats_id,
				}));

				const data = {
					method: 'set-stats',
					date: this.dateDB,
					stats: format,
				}

				axios
					.post('https://hcultras.kz/api/', JSON.stringify(data))
					.then(response => {
						if (response.status === 200) {
							console.log(response.data);

						}
					})
					.catch(error => console.log(error));

			},

			// Handles input validation after submission.
			handleSubmit(e) {
				e.preventDefault();
				this.form.validateFields((err, values) => {
					if ( !err ) {
						// console.log('Received values of form: ', values) ;
						this.savePlayers();
					}
				});
			},
		},
	})

</script>

<style lang="scss">
	input.ant-input {
		padding: 4px 6px;
		min-width: 30px;
	}
	.ant-btn {
		&.btn-minus,
		&.btn-plus {
			padding: 0;
			min-width: 30px;
		}
	}
	.counter {
		border-radius: 0;
		border-left: none;
		border-right: none;
		text-align: center;
	}
	.ant-form-item {
		&.set-stats__row {
			margin-bottom: 15px;
		}
	}
	.ant-form-item-control {
		line-height: normal;
	}
	@media screen and (max-width: 500px){
		/*
		.ant-select-dropdown-content {
			position: absolute;
			bottom: 50px;
			background: #fff;
		}*/
	}
</style>